









































import vue from 'vue';
import InstructionModel, {canBeSkipped} from '@/models/instruction.model';
import AnswerModel from '@/models/answer.model';
import ChatInstruction from '@/components/chat/ChatInstruction.vue';
import ChatStream from '@/components/chat/ChatStream.vue';
import ChatReplyForm from '@/components/chat/ChatReplyForm.vue';
import ChatEnding from '@/components/chat/ChatEnding.vue';
import ChatService, {MetaData} from '@/services/chat.service';
import ReplyModel from '@/models/reply.model';
import createChat, {EventHandlerConfig} from '@/factories/chat.factory';
import BrandingMessage from '@/components/chat/BrandingMessage.vue';
import TypingIndicator from '@/components/chat/TypingIndicator.vue';
import StarRatingComponent from 'vue-star-rating';

export default vue.extend({
    name: 'Chat',
    components: {
        StarRatingComponent,
        TypingIndicator,
        BrandingMessage,
        ChatEnding,
        ChatReplyForm,
        ChatStream,
        ChatInstruction,
    },
    async mounted() {
        this.chat = await this.createChat({
            halt: [this.onHalt],
            fetch: [this.onFetch],
            progress: [this.onProgress],
            'created-at': [this.onCreatedAt],
            'name-change': [this.onNameChange],
            'push-instruction': [this.onPushInstruction],
            'delete-instruction': [this.onDeletedInstruction],
            'advance-rejected': [this.onError],
            answer: [this.pushAnswer],
            'answer-rejected': [this.onError],
            'revert-rejected': [this.onError],
            reverted: [this.onReverted],
        });
    },
    data() {
        return {
            instructions: [] as InstructionModel[],
            answers: [] as AnswerModel[],

            chat: null as null | ChatService,
            chatName: '',
            date: null as Date | null,

            isTyping: false,

            validationError: '',
            valid: true,
            canReply: true,

            seenIntro: false,

            contentStyle: {
                paddingBottom: '1rem',
                overflow: null as null | string,
                height: '100%',
            },

            formStyle: {
                '--form-bottom-offset': '0px',
            },
        };
    },
    computed: {
        chatReplyKey(): string {
            return this.currentInstruction?.uuid ?? 'none';
        },
        lastWasAnswered(): boolean {
            return this.answers.map(
                (answer: AnswerModel): string => String(answer.instruction),
            ).includes(
                this.instructions[this.instructions.length - 1]?.uuid ?? '',
            );
        },
        currentInstruction(): InstructionModel | null {
            const lastInstruction = this.instructions[this.instructions.length - 1] ?? null;

            return (lastInstruction?.type === 'end' || !this.lastWasAnswered)
                ? lastInstruction
                : null;
        },
        canRevert(): boolean {
            const {currentInstruction} = this;
            return (
                currentInstruction !== null
                && this.chat?.canRevert(currentInstruction) === true
            );
        },
    },
    watch: {
        chatName(name: string): void {
            document.title = name.length
                ? `Chat - ${name}`
                : 'Chat';
        },
        currentInstruction(instruction: null|InstructionModel): void {
            this.valid = instruction !== null && canBeSkipped(instruction);
        },
        seenIntro(seen: boolean): void {
            this.$emit(seen ? 'intro-seen' : 'intro');
        },
        instructions(instructions: object[]): void {
            if (instructions.length === 0) {
                this.seenIntro = false;
            }
        },
    },
    methods: {
        handleLoading(value: boolean) {
            this.$emit('loading', value);
        },
        onFetch(): void {
            this.isTyping = true;
        },
        onHalt(): void {
            this.isTyping = false;
        },
        onNameChange(name: string): void {
            this.chatName = name;
        },
        onCreatedAt(date: Date): void {
            this.date = date;
        },
        onProgress(progress: number): void {
            this.$emit('progress', progress);
        },
        onPushInstruction(instruction: InstructionModel): void {
            this.instructions.push(instruction);
        },
        async createChat(eventHandlers: Record<string, Function[]>): Promise<ChatService> {
            const {community = '', script = ''} = this.$route.params;

            const chat = createChat(
                community,
                script,
                Object.keys(eventHandlers).reduce(
                    (carry: EventHandlerConfig[], type: string) => [
                        ...carry,
                        ...(eventHandlers[type].map(
                            (handler: Function) => ({
                                type,
                                handler: handler.bind(this),
                            }),
                        )),
                    ],
                    [],
                ),
            );

            if (!await chat.isAvailable()) {
                await this.$router.push({name: 'Closed'});
                return chat;
            }

            chat.setMetaData(this.$route.query as MetaData);

            if (this.$route.name !== 'AnonymousChat' && this.$route.query.cid) {
                chat.setConversationUuid(this.$route.query.cid as string);
            }

            chat.startConversation();
            return chat;
        },
        handleValidation(valid: boolean): void {
            this.valid = valid;
            if (valid) {
                this.validationError = '';
            }
        },
        onError(error: string): void {
            this.validationError = error;
            this.isTyping = false;
        },
        onMessageFinished(uuid: string): void {
            if (!this.chat) {
                throw new Error('Trying to fetch instruction for uninitialized chat');
            }

            this.chat.nextInstruction(uuid);
        },
        filterAnswers(uuid: string): void {
            this.answers = this.answers.filter(
                (answer) => answer.instruction !== uuid,
            );
        },
        filterInstructions(uuid: string): void {
            this.instructions = this.instructions.filter(
                (candidate) => candidate.uuid !== uuid,
            );
        },
        onReverted({uuid}: InstructionModel): void {
            this.validationError = '';
            this.filterAnswers(uuid);
        },
        onDeletedInstruction({uuid}: InstructionModel): void {
            this.filterInstructions(uuid);
            this.filterAnswers(uuid);
        },
        revertLastAnswer(): void {
            const {currentInstruction, chat} = this;

            if (currentInstruction && chat) {
                chat.revert(currentInstruction).catch((error: Error) => {
                    this.onError(error.message);
                });
            }
        },
        pushAnswer(answer: AnswerModel): void {
            this.answers.push(answer);
        },
        onReply(reply: ReplyModel): void {
            if (this.chat === null) {
                throw new Error('Trying to answer on an uninitialized chat.');
            }

            if (!this.canReply) {
                return;
            }

            this.canReply = false;

            this.chat.reply(reply.instruction, reply.data).then((success: boolean) => {
                this.canReply = true;

                if (success) {
                    this.pushAnswer(reply.displayAnswer);
                }
            });
        },
        onScroll(): void {
            this.handleFormOffset();
        },
        handleFormOffset(): void {
            const content = this.$refs.content as Element;
            const bottomOffset = Math.min(content?.scrollTop ?? 0, 0);
            this.formStyle['--form-bottom-offset'] = `${bottomOffset}px`;
        },
        onMessages(stream: object[]): void {
            if (stream.length > 2) {
                this.seenIntro = true;
            }
        },
        onFormResize({height}: {height: number}): void {
            this.contentStyle.paddingBottom = (
                height
                    ? `calc(${height}px + 2rem)`
                    : '1rem'
            );
        },
        onMessageAnimated(): void {
            this.scrollIntoView();
        },
        scrollIntoView(): void {
            const content = this.$refs.content as Element;

            if (!content) {
                return;
            }

            this.contentStyle.overflow = 'hidden';
            this.contentStyle.height = 'calc(100% - 1px)';
            content.scrollTo({top: 0, left: 0});
            setTimeout(() => {
                this.contentStyle.overflow = null;
                this.contentStyle.height = '100%';
            }, 500);
        },
    },
});
